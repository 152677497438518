<template>
    <div class="listContainerTeacher">
        <div v-for="(item,index) in event_rooms" :key="index" class="eventItem">
            <img :src="require('../../../assets/banner/'+handleEventName(item.event_name)+'.png')" class="eventImg"/>
            <div class="left">
                <div class="itemTitle" :style="{'color':colorMap[handleEventName(item.event_name)]}">{{handleEventName(item.event_name)}} China Final</div>
                <div class="itemName" :style="{'color':colorMap[handleEventName(item.event_name)]}">{{item.name}}</div>
                <div class="eventTime">
                    <div class="eventTimeContainer">
                        <div class="eventTimeItem">讲座入场：{{formateTime(item.event_start_time)}}</div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="eventStatusContainer" :style="{'background':colorMap[handleEventName(item.event_name)]}">
                    <div class="toContent"  @click="toRoom(item)">讲座入场</div>
                    <!-- <div class="toContent" v-if="item.status.status==='进行中'" @click="toRoom(item)">讲座入场</div>
                    <div class="outContent" v-if="item.status.status==='还未开始'">
                        <div class="txt">距离讲座倒计时</div>
                        <div class="time" :style="{'color':colorMap['color'+handleIndex(index)]}">
                            {{item.countDownTimes?item.countDownTimes:getFormatDuringTime(0)}}
                        </div>
                    </div>
                    <div class="outContent" v-if="item.status.status==='已结束'">
                        <div class="txt">已结束</div>
                        <div class="time" :style="{'color':colorMap['color'+handleIndex(index)]}">
                            {{getFormatDuringTime(0)}}
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getEventsByEducator } from '../../../api/index'
import { getUserId } from '../../../utils/store'
export default {
    data(){
        return{
            event_rooms:[],
            colorMap:{
                Artithon:'#11448D',
                Biothon:'#246EB3',
                Chemithon:'#445CB4',
                Econthon:'#E3A948',
                Geothon:'rgb(4,52,124)',
                Historithon:'rgb(171,9,61)',
                Mathethon:'rgb(9,54,80)',
                Physithon:'rgb(53,110,174)',
                Psychothon:'rgb(111,183,217)'
            }
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getEventsByEducator(getUserId()).then(res=>{
                if(res.data.code===0){
                    this.event_rooms = res.data.data.event_rooms.map(item=>{
                        if(item.status.status==='还未开始'){
                            this.handleRestTime(item)
                        }
                        return item
                    })
                }
            })
        },
        toRoom(item){
            this.$confirm('是否确认进入该讲座?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(res=>{
                let url = this.$router.resolve({
                    path:'/screen',
                    query:{
                        roomId:item.event_meeting_room_ids[0]
                    }
                })
                window.open(url.href,'_blank')
            })
        },
        formateTime(time){
            let item = new Date(time*1000);
            return `${item.getMonth()+1}-${item.getDate()} ${item.getHours()}:${item.getMinutes()>9?item.getMinutes():'0'+item.getMinutes()}`;
        },
        handleEventName(event){
            let event_array = event.split(" ")
            return event_array[0]
        },
        handleIndex(index){
            return index%4+1;
        },
        getFormatDuringTime(during) {
            var s = Math.floor(during / 1) % 60;
            during = Math.floor(during / 60);
            var i = during % 60;
            during = Math.floor(during / 60);
            var h = during;
            return  h + ':' + (i>9?i:'0'+i) + ':' + (s>9?s:'0'+s) ;
        },
        handleRestTime(item){
            let totalTime = item.status.rest_time
            item.countDownThread = window.setInterval(() => {
                this.$set(item,'countDownTimes',this.getFormatDuringTime(totalTime))
                totalTime--
                if (totalTime < 0) {
                    window.clearInterval(item.countDownThread)
                    item.status.status='进行中'
                }
                console.log(this.event_rooms)
            }, 1000)
        }
    }
}
</script>

<style lang="less">
.listContainerTeacher{
    .eventItem{
        width: 84%;
        height: 17vh;
    
        margin-bottom: 4vh;
        position: relative;
        display: flex;
        .eventImg{
            position: absolute;
            top:0;
            z-index: -1;
            width: 100%;
            height: 100%;
            display: block;
        }
        .left{
            width: 60%;
            overflow: hidden;
            .itemTitle{
                margin-top:2vh;
                font-size: 2.4vh;
                font-family: Impact;
                font-weight: 400;
                padding-left:30px;
                white-space:nowrap;
            }
            .itemName{
                font-size: 5vh;
                font-family: Impact;
                font-weight: 400;
                padding-left:30px;
                white-space:nowrap;
            }
            .eventTime{
                background: #32B67A;
                height: 2.6vh;
                width: 100%;
                margin-top:1vh;
                margin-left: 30px;
                color: white;
                font-size: 1.4vh;
                line-height: 2.6vh;
                font-family: Microsoft YaHei;
                font-weight: bold;
                .eventTimeContainer{
                    width: 90%;
                    margin:0 auto;
                    display: flex;
                    .eventTimeItem{
                        width: 50%;
                        text-align: center;
                    }
                }
            }
        }
        .right{
            width: 36%;
            display: flex;
            justify-content: center;
            align-items: center;
            .eventStatusContainer{
                width: 40%;
                height: 50%;
                margin-left: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                .toContent{
                    color: white;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    cursor: pointer;
                    padding: 3vh;
                }
                .outContent{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    .txt{
                        margin-top: 0.4vh;
                        font-size: 1.4vh;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #FFFFFF;
                        text-align: center;
                    }
                    .time{
                        background: white;
                        width: 80%;
                        padding: 0.6vh 4px;
                        font-family: Impact;
                        font-weight: 400;
                        font-size: 3vh;
                        text-align: center;
                        margin-bottom: 0.4vh;
                    }
                }
            }
        }
    }
}
</style>